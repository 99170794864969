.DefaultButton {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 11px;
  background: var(--default_button);
  transition: .2s;
  box-sizing: border-box;
}

.DefaultButton:disabled {
  filter: brightness(40%);
  cursor: not-allowed;
}

.ButtonText {
  transition: 0.2s;
  z-index: 10;
}

.Button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0 30px 30px 0;
  background: var(--default_button);
  font-size: 12px;
  filter: brightness(60%);
  cursor: not-allowed;
  width: 25%;
  min-width: 90px;
  border: solid 1px var(--input-border-default);
  transition: .3s;
  overflow: hidden;
}

.ActiveButtonBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--default-icon-active);
  border: 1px solid #4e4c4c;
  z-index: 1;
  opacity: 0.2;
  transform: translateX(100%);
  transition: 0.2s ease-out;
}

.ActiveButton {
  cursor: pointer;
  filter: brightness(100%);
}

@media (hover:hover) {
  .DefaultButton:not([disabled]):hover {
    transform: scale(0.98);
    filter: brightness(90%);
  }

  .ActiveButton:hover .ActiveButtonBackground {
    transform: translateX(0);
    opacity: 0.8!important;
  }

  .ActiveButton:hover .ButtonText{
    transform: scale(0.96);
  }
}

.ActiveButton:active .ActiveButtonBackground {
  transform: translateX(0);
  opacity: 1!important;;
}

.DefaultButton:not([disabled]):active {
  transform: scale(0.96);
  filter: brightness(80%);
}

.ActiveButton:active {
  box-shadow: var(--block-shadow-inset-bottom);
}

.ActiveButton:active > .ButtonText{
  transition: 0.2s;
  transform: scale(0.93);
}


@media (min-width: 600px) {
  .Button {
    font-size: 16px;
    max-width: 180px;
  }
}