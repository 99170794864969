:root {
  --background-color: #282c34;
  
  --block-content: #010c21;
  --input-border-default: #7c9dcd;
  --input-border-active: #306de7;

  --error-background: #95343469;
  --error-border: #e64646;

  --text-header: rgb(152, 162, 170);
  --text-color: rgb(241, 241, 241);
  --text-color-dark: #2a2828;
  --text-negative: rgb(244, 22, 22);

  --error-text: #e64646;
  --info-text: #0080ff;
  --success-text: #46e64e;
  --warn-text: #e6c346;

  --link-color: rgb(29, 161, 165);

  --block-shadow-inset-bottom: -5px -5px 5px -5px rgba(0, 0, 0, 0.397) inset;
  --block-shadow-inset-bottom-2: -5px -5px 5px -5px rgba(0, 0, 0, 0.697) inset;

  --default_button: linear-gradient(62deg, #de3843 0%, #e66019 100%);
  --positive_button: linear-gradient(62deg, #1c9721 0%, #0e6e0c 100%);
  --negative_button: linear-gradient(62deg, #721e24 0%, #fc0000 100%);

  --default-icon-button: linear-gradient(62deg, #de3843 0%, #e66019 100%);
  --positive-icon-button: linear-gradient(62deg, #1c9721 0%, #0e6e0c 100%);
  --negative-icon-button: linear-gradient(62deg, #721e24 0%, #fc0000 100%);
  --default-icon-active: linear-gradient(62deg, #4e4c4c 0%, #000000 100%);

  --header-background-color: #eb681c;
  --header-background: linear-gradient(62deg, #de3843 0%, #e66019 100%);
  --background-menu-color: rgb(2, 8, 26);
  --background-menu: radial-gradient( circle 815px at 23.4% -21.8%,  rgba(9,29,85,1) 0.2%, rgb(3, 8, 22)100.2% );


  --vk-color: #0077FF;
  --ok-color: #FF7700;
  --tg-color: #24A1DE;
  --twitter-color: rgb(45, 45, 45);
  --whatsapp-color: #28D146;
  --viber-color: #7360F2;

  --snackbar-info: #0080ff69;
  --snackbar-info-border: #0080ff69;

  --snackbar-success: #06b21469;
  --snackbar-success-border:  #0cb414;

  --snackbar-warn: #ffdd0069;
  --snackbar-warn-border: #e6c346;

  --snackbar-error: #95343469;
  --snackbar-error-border: #e64646;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Slab:wght@100..900&display=swap');

body {
  margin: 0;
  color: var(--text-color);
  background-color: var(--background-menu-color);
  background: var(--background-menu);
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  box-sizing: border-box;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}