.Header {
    background-color: var(--header-background-color);
    background: var(--header-background);
    width: 100%;
    height: 20%;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.47);

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.Header > h1 {
    text-shadow: 2px 2px 2px rgba(56, 55, 55, 0.3) ;
    font-weight: 400;
    font-size: 24px;
    margin: 0;
    margin-bottom: 8px;
}