.ResultBodyLink {
    display: flex;
    width: 100%;
    animation: ShowResultBodyLink 0.3s forwards;
    box-sizing: border-box;
    align-items: center;
    justify-content: left;

    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select:text;
}

.ResultBodyShortLink {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--header-background);
    padding: 8px 18px;
    padding-left: 10px;
    border-radius: 30px;
    transition: 0.3s;
    overflow: hidden;
}

.ResultBodyShortLinkBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: var(--positive_button);
    opacity: 0.1;
    transform: translateX(100%);
}

.ResultBodyShortLinkLogo {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2) inset;
    transition: .3s;
    z-index: 10;
}

.ResultBodyShort {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-left: 8px;
    color: var(--text-color);
    transition: 0.3s;
    z-index: 10;
}


@media (max-width: 600px) {
    .ResultBodyLink {
        align-items: center;
        justify-content: center;
    }

    .ResultBodyShortLink {
        box-sizing: inherit;
    }

    .ResultBodyShort {
        font-size: 16px;
    }
}

@media (hover:hover) {
    .ResultBodyShortLink:hover .ResultBodyShortLinkBackground{
        transition: ease-in .3s;
        opacity: 1;
        transform: translateX(0);
    }
    
    .ResultBodyShortLink:hover .ResultBodyShort {
        color: var(--text-color-dark);
    }    

    .ResultBodyShortLink:hover .ResultBodyShortLinkLogo {
        background: var(--text-color-dark);
    }

    .ResultBodyShortLink:hover .ResultBodyShortLinkLogo > svg {
        transition: .3s;
        transform: rotate(180deg);
    }
}

.ResultBodyShortLink:active .ResultBodyShortLinkBackground{
    transition: 0.3s;
    opacity: 1;
}

@keyframes ShowResultBodyLink {
    0% {
        opacity: 0;
        margin-left: -100%;
    }

    100% {
        margin-left: 0;
        opacity: 1;
    }
}