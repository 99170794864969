.ResultUrl {
    padding-right: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 5px;
}

.ResulUrltMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
}

.ResultUrl > p {
    max-width: calc(100% - 18px);
    font-size: 18px;
    margin: 0;
    margin-left: 8px;
}

.InputMenu {
    display: flex;
    width: 100%;
    border-radius: 30px;
    -webkit-box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.47);
}