.Body {
    height: 100%;
    padding: 26px 16px 36px 16px;
    min-height: 100%;
    flex: 1;
    box-sizing: border-box;
}

@media (min-width: 730px) {
    .Body {
        padding: 46px 12% 36px 12%;
    }    
}