.App {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Input {
  width: 100%;
  padding: 12px 18px;
  border-radius: 11px;
  background-color: var(--block-content);
  border: solid 1px var(--input-border-default);
  color: var(--text-color);
  font-size: 18px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.59) inset;
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.59) inset;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.59) inset;
}

.Input:focus {
  border: solid 1px var(--input-border-active);
  outline: none;
}

.HeaderText {
  margin: 0 0 10px 5px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;

  background: var(--header-background);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.47);
}