.SnackBarItem {
    position: fixed;
    width: 100%;
    max-width: 250px;
    height: 56px;
    padding-right: 10px;
    bottom: 20px;
    left: -100vw;
    background-color: var(--snackbar-info);
    animation: showSnackbar 0.3s forwards;
    border-radius: 10px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: 1000;
    cursor: pointer;
    box-shadow: var(--block-shadow);
}

.SnackBarItem > p {
    margin: 0;
    margin-left: 10px;
}


@keyframes showSnackbar {
    0% {
        opacity: 0;
        left: -100vw;
    }
    100% {
        left: 15px;
        opacity: 1;
    }
}

@keyframes deleteSnackbar {
    0% {
        left: 15px;
        opacity: 1;
    }
    100% {
        left: -100vw;
        opacity: 0;
    }
}

.MiniSnackbarItem {
    position: fixed;
    width: max-content;
    z-index: 10000;
    right: 0;
    left: 0;
    background-color: var(--positive-color);
    padding: 10px;
    border-radius: 12px;
    animation: ShowMiniSnackbar 1s forwards;
}

@keyframes ShowMiniSnackbar {
    0% {
        transform: scale(0.5);
        opacity: 0.3;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        top: -50px;
        opacity: 0;
    }
 }