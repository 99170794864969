.Spinner {
    -webkit-animation: ShowSpinner 2s linear infinite;
    -moz-animation: ShowSpinner 2s linear infinite;
    -ms-animation: ShowSpinner 2s linear infinite;
    -o-animation: ShowSpinner 2s linear infinite;
    animation: ShowSpinner 2s linear infinite;
}


@keyframes ShowSpinner {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}