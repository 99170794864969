
.Message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    padding: 10px 12px;
    padding-left: 18px;
    border-radius: 40px;
    background-color: var(--error-background);
    border: 1px solid var(--error-border);
    box-sizing: border-box;
    color: var(--error-border);
    animation: ShowMessage .3s forwards;
    overflow: hidden;
}

.Message > p {
    margin: 0;
    margin-right: 10px;
}

@keyframes ShowMessage {
    0% {
        transform: scale(1, 0);
        opacity: 0;
    }

    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}

@keyframes DeleteMessage {
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }

    100% {
        transform: scale(1, 0);
        opacity: 0;
    }
}

.Icon {
    display: flex;
}