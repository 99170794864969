
.IconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: 3s;
}

.IconButtonColor {
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  border-radius: 50%;
  cursor: pointer;
  background: var(--default-icon-button);
  overflow: hidden;
}

.IconButtonColor > svg {
  z-index: 10;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.741));
}

.IconButtonColorBackground {
  border: 1px solid #4e4c4c;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: var(--default-icon-active);
  z-index: 1;
  transform: translateX(44px) rotateY(90deg);
  border-radius: 50%;
  transition: 1s ease-out;
}

 @media (hover:hover) {
   .IconButton:not([disabled]):hover {
     background-color: rgba(240, 248, 255, 0.051);
   }

  .IconButtonColor:not([disabled]):hover > .IconButtonColorBackground {
    transition: 0.2s;
    opacity: 0.9;
    transform: translateX(0) rotateY(0);
  }

  .IconButtonColor:not([disabled]):hover svg{
    transition: 0.2s;
    transform: scale(0.95);
  }
}

.IconButtonColor:not([disabled]):active > .IconButtonColorBackground {
  transition: 0.2s;
  opacity: 1;
}

.IconButtonColor:not([disabled]):active svg {
  filter: drop-shadow(2px 4px 5px rgb(0, 0, 0));
  transform: scale(0.89);

}


.IconButton:not([disabled]):active {
  background-color: rgba(240, 248, 255, 0.087);
  transition: 1s ease-in;
}
