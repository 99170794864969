.ShareButtonA {
    z-index: 10;
    background-color: rgb(0, 91, 171);
    width: min-content;
    height: max-content;
    border-radius: 8px;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.471) inset;
    overflow: hidden;
}

.TextHeader {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    text-align: center;
    margin-bottom: 8px;
}

.ShareMenuWindow {
    margin-bottom: 10px;
}

.ShareMenu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ShareButton {
    padding: 5px;
    transition: .3s;
}

.ShareButton > svg {
    display: flex;
    filter: drop-shadow(3px 4px 3px #0000007e);
}

@media (hover:hover) {
    .ShareButton:hover {
        background: var(--block-content);
    }
}

.ShareButton:active {
    background: var(--block-content);
}


@media (min-width: 600px) {
    .ShareMenuWindow {
        margin-top: auto;
        margin-bottom: 0;
    }

    .ShareMenu {
        justify-content: flex-start;
    }

    .TextHeader {
        text-align: left;
    }
}