.ResultMenu {
    display: grid;
    margin-top: 20px;
    width: 100%;
    height: 100%;
    min-height: 200px;
    background-color: var(--block-content);
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.703);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.79) inset;
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.79) inset;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.79) inset;
    box-sizing: border-box;
    overflow: hidden;
}

.ResultBody {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.ResultBodyLeftMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 0;
}

.ResultBodyRightMenu {
    width: max-content;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    overflow: hidden;
}

.OrigResultLink {
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    padding: 10px 20px;
    box-sizing: border-box;
    color: var(--text-header);
    cursor: pointer;
    transition: .2s;
    font-size: 14px;
}

@media (hover:hover) {
    .OrigResultLink:hover {
        opacity: 0.7;
    }
}

.ShortSvgMinStop {
    transition: 0.3s;
}
.ShortSvgMaxStop {
    transition: 0.3s;
}

.QrCode {
    position: relative;
    border-radius: 8px;
    width: 100%;
    max-width: 200px;
    aspect-ratio: 1/1;
    border-radius: 18px;
    overflow: hidden;
}

.QrCode > canvas {
    width: 100%;
    height: 100%;
    position: relative;
}

.QrCode::after {
    box-shadow: 0px 0px 8px 14px rgba(0, 0, 0, 0.3) inset;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 18px;
}

@media (max-width: 600px) {
    .ResultBody {
        flex-direction: column;
    }
    
    .ResultBodyCode {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .ResultBodyRightMenu {
        width: 100%;
        height: max-content;
    }

    .ResultBodyLeftMenu {
        height: auto;
        padding: 10px;
    }
}
