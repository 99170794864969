.BannedMenu {
    width: 100%;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    max-width: 600px;
}

.BannedMenu > h1 {
    color: var(--text-negative);
    font-size: 22px;
    font-weight: 400;
}

.BannedMenu > p {
    margin-top: 20px;
}

.BannedMenu > svg {
    width: 150px;
    height: auto;
}

.Reward1WinToken {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-top: 50px;
    min-height: 200px;
    background-image: linear-gradient(0deg, #0133ca  10%, rgba(0, 0, 0, 0) 100%);
}

.Reward1WinTokenInfo {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 20px;
    text-align: left;
    box-sizing: border-box;
    padding-right: 0;
}

.Reward1WinTokenInfo > h1 {
    font-size: 16px;
}

.Reward1WinToken > img {
    width: 50%;
    margin-bottom: 0;
    margin-top: auto;
    margin-right: -6%;
    margin-left: auto;
}