.Footer {
    width: 100%;
    text-align: center;
    color: var(--text-header);
    font-size: 12px;
  }

  .FooterBanner {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 600px;
  }

  .FooterBanner > p {
    margin: 0;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
  }

  .FooterBannerHeaderLink {
    font-size: 22px;
  }